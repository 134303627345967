import React, { FunctionComponent } from "react"

interface StarSVGProps {}
interface StarProps {}

export const StarSVG: FunctionComponent<StarSVGProps> = () => {
  return (
    <svg
      width="100"
      height="97"
      viewBox="0 0 100 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1936_266)">
        <path
          d="M49.1034 14.4836C49.4702 13.7405 50.53 13.7405 50.8968 14.4836L60.0676 33.0627C60.2132 33.3576 60.4944 33.5621 60.8197 33.6096L81.3296 36.6074C82.1496 36.7273 82.4763 37.7351 81.8827 38.3133L67.0435 52.7667C66.8077 52.9964 66.7 53.3275 66.7557 53.6521L70.2575 74.0696C70.3976 74.8866 69.5401 75.5095 68.8065 75.1237L50.4655 65.4784C50.1742 65.3252 49.826 65.3252 49.5346 65.4784L31.1937 75.1237C30.4601 75.5095 29.6025 74.8866 29.7427 74.0696L33.2445 53.6521C33.3002 53.3275 33.1925 52.9964 32.9566 52.7667L18.1174 38.3133C17.5238 37.7351 17.8506 36.7273 18.6705 36.6074L39.1804 33.6096C39.5058 33.5621 39.787 33.3576 39.9325 33.0627L49.1034 14.4836Z"
          fill="#FFC200"
          stroke="#FFC200"
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1936_266"
          x="-4"
          y="-4"
          width="108"
          height="108"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_1936_266"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.760784 0 0 0 0 0 0 0 0 0.32 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1936_266"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1936_266"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export const Star: FunctionComponent<StarProps> = () => {
  return (
    <svg
      width="71"
      height="69"
      viewBox="0 0 71 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.8086 4.48338C35.1755 3.74021 36.2352 3.74021 36.6021 4.48338L45.7729 23.0625C45.9184 23.3574 46.1996 23.5618 46.525 23.6094L67.0349 26.6072C67.8548 26.727 68.1816 27.7349 67.588 28.313L52.7488 42.7664C52.5129 42.9962 52.4053 43.3273 52.4609 43.6518L55.9628 64.0694C56.1029 64.8863 55.2453 65.5093 54.5117 65.1235L36.1708 55.4782C35.8794 55.325 35.5313 55.325 35.2399 55.4782L16.899 65.1235C16.1653 65.5093 15.3078 64.8863 15.4479 64.0694L18.9498 43.6518C19.0054 43.3273 18.8978 42.9962 18.6619 42.7664L3.82269 28.313C3.2291 27.7349 3.55588 26.727 4.37579 26.6072L24.8857 23.6094C25.2111 23.5618 25.4922 23.3574 25.6378 23.0625L34.8086 4.48338Z"
        fill="#D96716"
        stroke="#D96716"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
